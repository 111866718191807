<template>
  <div>
    <OrganizationDetailItemEditCard confirmation-message="Organisatie informatie werd succesvol gewijzigd"
      :promises.sync="promises" title="Bewerk organisatie informatie" :organizationId="organizationId"
      update-store-action="organizationModule/updateOrganizationInformation"
      :update-store-action-payload="{ organizationId: organizationId, organizationInformation: organizationInformation }"
      detail-view-route-location="">
      <template v-slot:form-fields>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field v-model="organizationInformation.registrationDate" label="Registratie Datum"
              prepend-icon="mdi-calendar" persistent-placeholder clearable required disabled />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="organizationInformation.vatNumber" persistent-placeholder label="btw nummer"
              type="text" />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="organizationInformation.bankAccountNumber" persistent-placeholder label="Bankrekening"
              type="text" />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="organizationInformation.contactEmail" persistent-placeholder label="Contact email"
              type="text" :rules="getEmailRules(false)" />
          </v-col>

          <v-col cols="12">
            <v-subheader class="text-h5 pl-0"><v-icon class="pr-2">mdi-map-marker</v-icon> Adres</v-subheader>
            <AddressInput v-model="organizationInformation.address" required="true"/>
          </v-col>
        </v-row>
      </template>
    </OrganizationDetailItemEditCard>
  </div>
</template>
  
<script>
import OrganizationDetailItemEditCard from './OrganizationDetailItemEditCard.vue'
import AddressInput from "../../../components/shared/fields/AddressInput.vue";
import { applyDateFormat } from "@/shared/utils/dateUtils"
import { getEmailRules } from "@/shared/utils/inputRulesUtils"
import { DetailedTimeStamp } from "@/shared/types/DetailedTimeStamp"


export default {
  name: "OrganizationDetailEditOrganizationInformation",
  props: {
    organizationId: {
      type: String,
      required: true
    }
  },
  components: {
    OrganizationDetailItemEditCard,
    AddressInput
  },
  data() {
    return {
      organizationInformation: {
        registrationDate: null,
        vatNumber: null,
        contactEmail: null,
        address: {},
      },
      promises: [
        this.$store.dispatch('organizationModule/fetchOrganizationInformation', this.organizationId)
          .then((organizationInformation) => {
            this.organizationInformation = organizationInformation
            this.organizationInformation.registrationDate = applyDateFormat(DetailedTimeStamp.fromJson(organizationInformation?.registrationDate).getUTCTimeAsDate())
            if (!this.organizationInformation.address) {
              this.organizationInformation.address = {}
            }
          })
      ]
    }
  },
  methods: {
    applyDateFormat,
    getEmailRules,
  },
}
</script>
  